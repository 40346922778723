export class NumbersUtil {
    static lt20Numbers: string[] = [
        '',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
    ];

    static gt20Numbers: string[] = [
        '',
        '',
        'twenty',
        'thirty',
        'forty',
        'fifty',
        'sixty',
        'seventy',
        'eighty',
        'ninety',
    ];

    static regex: RegExp = /^(\d{2})(\d{1})(\d{2})$/;

    static separator: string = ' ';

    static getLT20 = (n: number) => NumbersUtil.lt20Numbers[n];
    static getGT20 = (n: number) =>
        NumbersUtil.gt20Numbers[Number(n.toString()[0])] +
        NumbersUtil.separator +
        NumbersUtil.lt20Numbers[Number(n.toString()[1])];

    static toWrittenForm(num: number): string {
        const getGT20 = NumbersUtil.getGT20;
        const getLT20 = NumbersUtil.getLT20;
        const regex = NumbersUtil.regex;
        const separator = NumbersUtil.separator;

        if (num === 0) {
            return 'zero';
        }

        const numStr: string = String(num);
        if (numStr.length > 5) {
            throw new Error('overflow'); // Does not support converting more than 5 digits yet
        }

        const numArray = ('00000' + numStr).substr(-5).match(regex); // left pad zeros
        const n1: number = numArray?.[1] ? Number(numArray[1]) : 0;
        const n2: number = numArray?.[2] ? Number(numArray[2]) : 0;
        const n3: number = numArray?.[3] ? Number(numArray[3]) : 0;

        let str: string = '';

        str += n1 !== 0 ? (getLT20(n1) || getGT20(n1)) + separator + 'thousand ' : '';
        str += n2 !== 0 ? getLT20(n2) + separator + 'hundred ' : '';
        str += n3 !== 0 && str !== '' ? 'and ' : '';
        str += n3 !== 0 ? getLT20(n3) || getGT20(n3) : '';

        return str.trim();
    }
}
