export enum FieldType {
    autoComplete = 'autoComplete',
    dateTime = 'dateTime',
    file = 'file',
    hidden = 'hidden',
    label = 'label',
    number = 'number',
    paragraph = 'paragraph',
    richEditor = 'richEditor',
    select = 'select',
    switch = 'switch',
    text = 'text',
    textarea = 'textarea',
    url = 'url',
}
