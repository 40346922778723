import { FC, ReactNode } from 'react';

import { BaseModalType, useModalContext } from '@admin/organisms/BaseModal';
import { Icon } from '@common/atoms/Icon';

interface Props {
    children: ReactNode;
    contextKey: BaseModalType;
}

export const Header: FC<Props> = ({ children, contextKey }: Props) => {
    const { closeModal } = useModalContext(contextKey);
    if (!children) return null;

    return (
        <header className={'flex justify-between'}>
            {children}
            <Icon.x onClick={closeModal} />
        </header>
    );
};
