import { ReactNode } from 'react';

import { Button, ButtonVariant } from '@admin/atoms/Button';
import { BaseModalType, useModalContext } from '@admin/organisms/BaseModal';

interface Props {
    children: ReactNode;
    contextKey?: BaseModalType;
    variant?: ButtonVariant;
    filled?: boolean;
}

export const OpenModalButton = ({
    children,
    variant = ButtonVariant.primary,
    filled = true,
    contextKey = BaseModalType.Default,
}: Props) => {
    const { openModal } = useModalContext(contextKey);

    return (
        <Button variant={variant} filled={filled} onClick={openModal}>
            {children}
        </Button>
    );
};
